import React, { useEffect } from "react";
import BagroundImage from "../assets/BG BANNER.jpg";
import Building from "../assets/Polygon 1.png";
import { ReactComponent as Learn } from "../assets/chevron-down.svg";
import { ReactComponent as Started } from "../assets/Diagonal  Right Up Arrow.svg";

function Banner() {
	return (
		<div
			id="Home"
			className="bg-[#000] w-full h-[90vh] flex items-center justify-center allign-center p-4"
		>
			<div
				className="w-full h-full flex flex-col rounded-[25px] relative"
				style={{
					background: `url('${BagroundImage}')`,
					backgroundRepeat: "no-repeat",
					backgroundSize: "cover"
				}}
			>
				<div className="w-full h-full absolute top-0 left-0 bg-[#000]  opacity-10 rounded-[25px]" />
				<div
					className="w-full  md:w-[85%] p-10 relative"
				>
					<p className="p-4 rounded-full border-[#FFF] w-[150px] sm:w-[200px] text-center border-2 text-white font-poppins text-[10px] sm:text-[15px] my-4">
						Welcome to a world
					</p>
					<h1 className="w-full text-white  font-poppins text-[40px] sm:text-[70px]">
						Motiwala Builders
						FROM BLUEPRINT TO REALITY
					</h1>
					<p className="text-white font-poppins sm:text-[25px] my-4">
						Welcome to a world where your dreams take flight. At Motiwala Builders, we craft more than
						structures; we weave dreams into architectural wonders.
					</p>
				</div>
			</div>
		</div>
	);
}

export default Banner;
