import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Image1 from "../assets/Slide Image (1).png";
import Image2 from "../assets/Slide Image (2).png";
import Image3 from "../assets/Slide Image (3).png";
import Image4 from "../assets/Slide Image (4).png";
import Image5 from "../assets/Slide Image.png";
import AOS from "aos";
import "aos/dist/aos.css";
const LeftArrow = ({ width, height, onClick }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={width}
		height={height}
		className="cursor-pointer"
		onClick={onClick}
		viewBox="0 0 24 24"
		fill="none"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8.1213 10.5H21V13.5H8.1213L13.0606 18.4393L10.9393 20.5606L2.37866 12L10.9393 3.43933L13.0606 5.56065L8.1213 10.5Z"
			fill="#999999"
		/>
	</svg>
);

const RightArrow = ({ width, height, onClick }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={width}
		height={height}
		className="cursor-pointer"
		onClick={onClick}
		viewBox="0 0 24 24"
		fill="none"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M15.8787 10.5H3V13.5H15.8787L10.9393 18.4393L13.0606 20.5606L21.6213 12L13.0606 3.43933L10.9393 5.56065L15.8787 10.5Z"
			fill="#999999"
		/>
	</svg>
);

const Carousel = () => {
	let main = useRef(null);
	const [activeIndex, setActiveIndex] = useState(0);
	const [Array, setArray] = useState([Image1, Image2, Image3, Image4, Image5]);
	let arrMain = [Image1, Image2, Image3, Image4, Image5];

	const renderDots = (index) => (
		<div
			key={index}
			className={`mx-2 rounded-[100%] w-[10px] h-[10px] md:h-[10px] lg:h-[10px] sm:h-[10px] xs:h-[10px] h-[10px] ${index === activeIndex ? "bg-[#071114] font-bold" : "bg-gray-500"
				}`}
		/>
	);

	function Next() {
		let arr = Array;
		if (arr.length < 1) arr = arrMain;
		let lastElement = arr.pop();

		arr.unshift(lastElement);
		setArray(arr);
		AOS.init({
			delay: 1000
		});
		main.dataset = {
			"data-aos": "fade-down-right",
			"data-aos-offset": "200",
			"data-aos-easing": "ease-in-sine",
			"data-aos-duration": "3000",
			"data-aos-delay": 100
		};
		if (activeIndex < arr.length - 1) {
			setActiveIndex(activeIndex + 1);
		} else {
			setActiveIndex(0);
		}
	}


	function Previous() {
		let arr = Array;
		if (arr.length < 1) arr = arrMain;
		let lastElement = arr.pop();

		arr.unshift(lastElement);
		setArray(arr);
		AOS.init({
			delay: 1000
		});
		main.dataset = {
			"data-aos": "fade-down-right",
			"data-aos-offset": "200",
			"data-aos-easing": "ease-in-sine",
			"data-aos-duration": "3000",
			"data-aos-delay": 100
		};
		if (activeIndex > 0) {
			setActiveIndex(activeIndex - 1);
		} else {
			setActiveIndex(arr?.length - 1);
		}
	}
	useEffect(() => {
		const Time = setInterval(() => {
			Next()
		}, 5000);
		return () => clearInterval(Time);
	}, [activeIndex]);

	return (
		<div className="relative overflow-hidden my-[5%] flex flex-col justify-center align-center items-center">
			<h1 className="text-[#434343] text-center font-playfair font-bold text-[50px] mb-[20px]">
				Motiwala Projects
			</h1>
			<div
				className="flex flex-row overflow-hidden relative my-[1%] md:h-[60vh] lg:h-[60vh] sm:h-[40vh] xs:h-[40vh] h-[40vh] item-center justify-center w-full"
				data-aos="fade-zoom-in"
				data-aos-offset="200"
				data-aos-easing="ease-in-sine"
				data-aos-duration="3000"
				data-aos-delay={`${100}`}
			>
				<img
					src={Array[0]}
					style={{ filter: "opacity(40%)" }}
					className={`rounded-[40px] w-[80%] md:w-[50%]  md:h-[30vh] lg:h-[35vh] sm:h-[15vh] xs:h-[15vh] h-[15vh] flex-shrink-0 slick-center z-10 absolute left-[5%] top-[25%]`}
				/>

				<img
					src={Array[1]}
					style={{ filter: "opacity(90%)" }}
					className={`rounded-[40px] w-[80%] md:w-[50%]  md:h-[40vh] lg:h-[45vh] sm:h-[25vh] xs:h-[25vh] h-[25vh] flex-shrink-0 slick-center z-20 absolute left-[15%] top-[15%]`}
				/>
				<div
					className={"rounded-[40px] w-[80%] lg:w-[50%]  md:w-[55%]  md:h-[55vh] lg:h-[60vh] sm:h-[40vh] xs:h-[40vh] h-[40vh] flex-shrink-0 slick-center z-50"}
					ref={main}
				>
					<img
						src={Array[2]}
						className={"rounded-[40px] w-full md:h-[55vh] lg:h-[60vh] sm:h-[40vh] xs:h-[40vh] h-[40vh] flex-shrink-0 slick-center z-50"}
					/>
				</div>
				<img
					src={Array[3]}
					style={{ filter: "opacity(90%)" }}
					className={`rounded-[40px] w-[80%] md:w-[50%]  md:h-[40vh] lg:h-[45vh] sm:h-[25vh] xs:h-[25vh] h-[25vh] flex-shrink-0 slick-center z-20 absolute right-[15%] top-[15%]`}
				/>
				<img
					src={Array[4]}
					style={{ filter: "opacity(40%)" }}
					className={`rounded-[40px] w-[80%] md:w-[50%]  md:h-[30vh] lg:h-[35vh] sm:h-[15vh] xs:h-[15vh] h-[15vh] flex-shrink-0 slick-center z-10 absolute  right-[5%] top-[25%]`}
				/>
			</div>

			<div className="flex justify-center mt-4 items-center ">
				<LeftArrow width="24" height="24" onClick={() => Previous()} />
				<div className="flex mx-[20px]">{Array.map((a, i) => renderDots(i))}</div>
				<RightArrow width="24" height="24" onClick={() => Next()} />
			</div>
		</div>
	);
};

export default Carousel;
