import React, { useEffect } from "react";
import LastExit from "../assets/LastExit.png";
import LastExitLogo from "../assets/LastExitLogo.png";

import Prestige from "../assets/Prestige 33.jpg";
import PrestigeLogo from "../assets/PrestigeLogo.png";


import Signature from "../assets/Signature.png";
import SignatureLogo from "../assets/SignatureLogo.png";

import Metropolis from "../assets/Metropolis.jpg";
import MetropolisLogo from "../assets/MetropolisLogo.png";

function Projects() {
	return (
		<div id="Projects" className="relative overflow-hidden my-[5%] flex flex-col justify-center align-center items-center">
			<h1
				className="text-[#000000] text-center font-playfair text-3xl md:text-4xl lg:text-5xl font-semibold leading-[188.235%]"
			>
				Upcoming Projects
			</h1>
			<div className="w-full grid grid-cols-1 md:grid-cols-3 gap-0 m-[5%] mx-[0%]">
				{[
					{
						Logo: PrestigeLogo,
						background: Prestige,
						Type: "Upcoming",
						Heading: "Prestige 33",
						Text: "Prestige33 is a premium real estate project that redefines modern living with its elegant design and prime location. Offering luxurious apartments and world-class amenities, it promises a lifestyle of comfort and sophistication.",
						LogoStyle: "w-[80px] h-[65px] flex-shrink-0",
						link: "http://prestige.pk/",
						ColSpan: "col-span-1"
					},
					{
						Logo: SignatureLogo,
						background: Signature,
						Type: "Ongoing Project",
						Heading: "Metropolis  Signature",
						Text: "Metropolis Signature is a prestigious mixed-use development in Karachi, featuring luxurious residential and modern commercial spaces. With a project cost of 5000 million,  setting a new standard for sophisticated urban living and business environments.",
						LogoStyle: "w-[60px] h-[45px]  flex-shrink-0 ",
						link: "https://metropolissignature.com.pk/",
						ColSpan: "col-span-2"
					},
					{
						Logo: MetropolisLogo,
						background: Metropolis,
						Type: "Ongoing Project",
						Text: "Metropolis is a prestigious mixed-use development in Karachi, blending residential and commercial spaces. With a project cost of 8000 million, it offers luxurious amenities and modern design, setting a new standard for urban living and business in the city.",
						Heading: "Metropolis",
						LogoStyle: "w-[90px] h-[45px]  flex-shrink-0 ",
						link: "https://metropolis.com.pk/",
						ColSpan: "col-span-2"
					},
					{
						Logo: LastExitLogo,
						background: LastExit,
						Type: "Upcoming",
						Heading: "Last Exit",
						Text: "Last Exit, situated on Super Highway M-9 in Karachi, is a premier residential and commercial development with a project cost of 6000 million. This modern and luxurious project offers state-of-the-art amenities and innovative design, making it an ideal choice for both living and business purposes.",
						LogoStyle: "w-[80px] h-[65px] flex-shrink-0",
						link: "http://lastexit.pk/",
						ColSpan: "col-span-1"
					},
				].map((a, i) => (
					<div
						className={`${a?.ColSpan}  h-[60vh] md:h-[80vh] w-full p-4 pb-6 flex flex-col justify-end align-center relative overflow-hidden group`}
					>
						<div
							className="absolute inset-0 bg-no-repeat bg-cover transition-transform duration-300 group-hover:scale-110"
							style={{
								backgroundImage: `linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url('${a?.background}')`,
								backgroundRepeat: "no-repeat",
								backgroundSize: "cover"
							}}
						></div>

						<div className="relative z-10">
							<img src={a?.Logo} className={a?.LogoStyle} />
							<p className="absolute  text-[#FFF] text-[40px] md:text-[60px] text-center w-full font-playball bottom-0  group-hover:opacity-0 opacity-100 ">
								{a?.Heading}
							</p>
						</div>

						<div className="absolute w-[50vw] left-auto top-10 flex flex-col justify-center items-start bg-[#000] p-6 opacity-0 transition-opacity duration-300 group-hover:opacity-100">
							<p className="text-[18px] md:text-[35px] font-semibold text-[#FFF] font-playball ">
								{a?.Heading}
							</p>
							<p className="mt-4 text-[9px] md:text-[12px] font-semibold text-[#FFF] font-poppins">
								{a?.Text}
							</p>
						</div>
					</div>

				))}
			</div>
		</div>
	);
}

export default Projects;
