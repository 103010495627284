import React, { useEffect, useState } from "react";
import Logo from "../assets/HLogo.png";

function Header() {
	const [hash, setHash] = useState('');

	useEffect(() => {
		// Set initial hash value when the component mounts
		setHash(window.location.hash);

		// Listen for hash changes
		const onHashChange = () => {
			setHash(window.location.hash);
		};

		window.addEventListener('hashchange', onHashChange);

		return () => {
			window.removeEventListener('hashchange', onHashChange);
		};
	}, []);
	return (
		<div
			className="sm:sticky top-0 z-[100000000] shadow-2xl bg-[#000] w-full h-[100%] flex-col sm:flex-row flex align-content-center justify-between items-center py-3 px-6"
		>
			<img
				className="w-[180px] h-[40px] sm:w-[180px]  sm:h-[40px] mb-4 sm:mb-0"
				src={Logo}
				alt={"Logo"}
			/>
			<div className="grid grid-cols-5 align-content-center justify-end items-end bg-[#F1F1F1] px-2 py-2 rounded-full">
				{[
					{ name: "Home", link: "" },
					{ name: "About", link: "#About" },
					{ name: "Projects", link: "#Projects" },
					{ name: "Services", link: "#Services" },
					{ name: "Contact", link: "#Contact" }
				].map((a, i) => (
					<a
						className={`
						cursor-pointer ${hash == a?.link && "bg-[#000] rounded-full text-white font-[800] px-3"} p-2 text-center text-primary font-poppins 
						text-[12px] font-[500] md:text-[13px]`
						}
						href={a?.link}
					>
						{a?.name}
					</a>
				))}
			</div>
		</div>
	);
}

export default Header;
