import { useEffect } from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { MdAlternateEmail } from "react-icons/md";
import { HiBuildingOffice2 } from "react-icons/hi2";
import { RiHomeOfficeLine } from "react-icons/ri";

const HubSpotForm = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//js.hsforms.net/forms/embed/v2.js";
    script.charset = "utf-8";
    script.type = "text/javascript";
    script.async = true;

    script.onload = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: "44958761",
          formId: "69290df8-7d97-4f53-b48b-5243f5b32a9f",
          region: "na1",
          target: "#hubspot-form"
        });
      }
    };

    document.body.appendChild(script);
  }, []);

  return (
    <div id="Contact" className="relative overflow-hidden m-[5%] flex flex-col justify-start align-start items-center ">
      <h4 className="text-primary text-center font-work-sans text-[35px] md:text-[55px]">
        CONTACT	MOTIWALA BUILDERS
      </h4>
      <h4 className="text-[#888888] text-center font-work-sans text-[13px] md:text-[22px] mb-[20px]">
        Connect with Motiwala Builders for Inquiries, Collaborations, and Expert Guidance
      </h4>
      <p className="text-[#888888] text-center font-work-sans text-[9px] md:text-[15px]">
        We would love to hear from you! Whether you have a project in mind, need expert advice, or want to collaborate, Motiwala Builders is here to help. Reach out to us for inquiries, consultations, or any information about our services. Let’s build something exceptional together!
      </p>
      <div className="w-full hidden md:grid md:grid-cols-5 place-items-center gap-2 my-[3%]">
        {[{
          Icon: <FaPhoneAlt className="w-[20px] h-[20px] text-[#0000FF]" />
          ,
          Heading: "PHONE",
          Text: "03330444938"
        },
        {
          Icon: <FaWhatsapp className="w-[30px] h-[30px] text-[#00FF00]" />,
          Heading: "WHATSAPP",
          Text: "03330444936"
        },
        {
          Icon: <MdAlternateEmail className="w-[30px] h-[30px] text-[#f01d2e]" />,
          Heading: "EMAIL",
          Text: "info@metropolis.pk"
        },
        {
          Icon: <HiBuildingOffice2 className="w-[50px] h-[50px]" />,
          Heading: "Head office",
          Text: "Shop # 2, Com 7, off Jinnah Avenue, Malir Cantonment, Karachi, 75080, Pakistan",

        },
        {
          Icon: <RiHomeOfficeLine className="w-[50px] h-[50px] text-[#795F22]" />,
          Heading: "Metropolis signature office",
          Text: "33, 82 / 3 DEH dozan kda scheme, main Jinnah Ave, Karachi"
        }].map(a => (
          <div className="w-full flex flex-row align-center justify-center items-center gap-4">
            {a?.Icon}
            <div className="flex flex-col align-center justify-center">
              <p className="text-[15px] font-[500] font-worksans">{a?.Heading}</p>
              <p className="text-[10px] text-[#888] font-[500] font-worksans">{a?.Text}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="flex flex-col-reverse  md:flex-row align-center justify-center w-full gap-4 my-[30px]">
        <div id="hubspot-form" className="flex-1 px-[5%]"></div>
        <div className="flex-1 relative">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3 616.664423766868!2d67.1915783!3d24.9775295!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb349052d8469cd%3A0x8f868fd5865205a8!2sMetropolis%20Signature!5e0!3m2!1sen!2s!4v1722686378698!5m2!1sen!2s"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade" 
          ></iframe>
        </div>
      </div>

    </div>
  );
};

export default HubSpotForm;