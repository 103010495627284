import React from "react";
import Image from "../assets/image 13.png";
import Logo from "../assets/Logo.png";
import { FaEnvelope, FaPhone } from 'react-icons/fa'

function Footer() {
	return (
		<div className="relative overflow-hidden flex flex-col justify-center align-center items-center gap-0 h-[100%]">
			<div
				className="w-full grid grid-cols-3 md:grid-cols-4 gap-2  items-center justify-center h-[100%] px-[10px] lg:px-[10%] md:px-[30px] sm:px-[20px] py-[3%]"
				style={{
					background: `url('${Image}')`,
					backgroundRepeat: "no-repeat",
					backgroundSize: "cover"
				}}
			>
				<div className="w-full overflow-hidden lg:w-[240px] col-span-3 md:col-span-1 flex flex-row justify-center align-center items-center">
					<img className="w-[100px] lg:w-[240px]" src={Logo} />
				</div>
				{[
					{
						name: "Company Information",
						Children: [
							{ link: "#About", name: "About Us" },
							{ link: "#About", name: "Vision" },
							{ link: "#About", name: "Our Mission" },
							{ link: "#CEO", name: "CEO Message" }
						]
					},
					{
						name: "Pages",
						Children: [
							{ link: "#", name: "Home Page" },
							{ link: "#Projects", name: "Our Projects" },
							{ link: "#Services", name: "Our Services" },
							{ link: "#Contact", name: "Contact Us" }
						]
					},
					{
						name: "Services",
						Children: [
							{
								link: "#Services", name: "Commercial Property"
							},
							{ link: "#Services", name: "Hospitality Property" },
							{ link: "#Services", name: "Specialized Facility" },
							{ link: "#Services", name: "Residential Property" }
						]
					}
				].map((b) => (
					<div className="flex flex-col justify-start items-start gap-1">
						<p className="text-white w-full font-inter text-[12px] md:text-[18px] font-medium uppercase my-[5px] md:my-[20px]">
							{b?.name}{" "}
						</p>

						{b?.Children.map((a) => (
							<a href={a?.link} className="text-white font-inter text-[10px] md:text-[15px] font-thin px-[1%] md:pr-[5%] capitalize cursor-pointer hover:border-b-[1px]  hover:border-color-white">
								{a?.name}
							</a>
						))}
					</div>
				))}
				<p className="text-[#FFF] mt-[2%] col-span-3 md:col-span-4 text-center font-poppins text-xs md:text-xl font-medium leading-[30px]">
					© {new Date().getFullYear()} Motiwala Builders. All rights reserved.{" "}
				</p>

			</div>
			<div className="bg-[#FFF] w-full px-4 py-4 flex-col flex justify-center align-center items-center">
				<div className="container mx-auto flex flex-col lg:flex-row justify-between items-center text-sm">
					<p className="text-primary mb-2 lg:mb-0">
						Designed and Developed By Star System
					</p>
					<div className="flex items-center space-x-4">
						<a
							href={`mailto:iqasan@gmail.com`}
							className="flex items-center text-primary hover:text-blue-500 transition-colors duration-300 no-underline"
							style={{ textDecoration: 'none' }}
						>
							<FaEnvelope className="mr-2" /> iqasan@gmail.com
						</a>
						<a
							href={`tel:+923332128721`}
							className="flex items-center text-primary hover:text-blue-500 transition-colors duration-300 no-underline"
							style={{ textDecoration: 'none' }}
						>
							<FaPhone className="mr-2" /> +923332128721
						</a>
					</div>
				</div>
			</div >
		</div >
	);
}

export default Footer;
