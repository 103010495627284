import React, { useEffect } from "react";
import BagroundImage from "../assets/AboutMain.jpg";
import Features1 from "../assets/Projects/Project (4).jpeg";
import Features2 from "../assets/Metropolis.jpg";
import Features3 from "../assets/Signature.png";
import Features4 from "../assets/Prestige 33.jpg";
import Logo1 from "../assets/image-removebg-preview (27) 1.png";
import Logo2 from "../assets/image-removebg-preview (28) 1.png";
import Logo3 from "../assets/image-removebg-preview (29) 1.png";

function AboutUs() {
	return (
		<div id="About" className="relative overflow-hidden m-[5%] flex flex-col justify-start align-start items-center mb-0">
			<h4 className="text-primary text-center font-work-sans text-[35px] md:text-[55px]">
				ABOUT	MOTIWALA BUILDERS
			</h4>
			<h4 className="text-[#888888] text-center font-work-sans text-[13px] md:text-[22px] mb-[20px]">
				Building Legacies With Excellence Trust And Innovation
			</h4>
			<p className="text-[#888888] text-center font-work-sans text-[9px] md:text-[15px]">
				The A.K. <b>Motiwala Group </b>has a rich history dating back to the pre-partition era when they
				established their first jewelery outlet in Karachi's Gold Market in 1960. Founded by Mr. Noor
				Muhammad Jummah <b>Motiwala</b>, the outlet soon became known for its quality, latest designs,
				and customer satisfaction. Today, the group is led by Mr. Wahid Abdul Qadir <b>Motiwala</b>, the
				CEO of <b>Motiwala Builders and Shahnaz Akther & Company</b>, two business arms of the group
				that ventured into real estate development and construction in 1976.
			</p>
			<p className="text-[#888888] text-center font-work-sans text-[9px] md:text-[15px]">
				<b>Motiwala Builders and Shahnaz Akther & Company</b> were established with a clear mission - to
				raise the bar for quality housing and commercial projects in Karachi, Pakistan. Over the last
				32 years, the group has launched more than 57 projects in strategic locations, including posh
				residential and commercial areas. Of these, 27 have been completed under Mr. Wahid
				Abdul Qadir <b>Motiwala's Leadership</b>, such as <b>Motiwala</b> Mansion, Amna Villas, <b>Motiwala </b>
				Arcade, Bahadurabad Arcade, Al Amna Heights, Z.N Tower, City Avenue, City View & City
				Centre Residency.
			</p>
			<p className="text-[#888888] text-center font-work-sans text-[9px] md:text-[15px]">
				The group's commitment to excellence has earned them a reputation as a reliable and trust
				worthy builder and developer in Karachi. Their latest projects, Metropolis and Metropolis
				Signature, are a testament to their expertise and professionalism. Designed and engineered
				by experts from the UAE, these projects blend residential and commercial properties to meet
				the needs of modern urban living. The A.K. <b>Motiwala Group's </b>story is one of success, diversification, strong leadership, and excellent team spirit, and they continue to strive towards new
				heights of excellence in the years to come.
			</p>
			<img
				className="w-full md:h-[70vh] rounded-[25px]  my-[5%] "
				src={BagroundImage}
			/>

			<h4 className="text-primary text-center font-poppins text-[25px] md:text-[35px]">
				Making Inspiring Environments And Fosters Innovation
			</h4>
			<h4 className="text-[#888888] text-center font-work-sans text-[13px] md:text-[15px] my-[20px]">
				<b className="text-secondary">Motiwala Builders</b> Creates Inspiring Environments, Fosters Innovation, And Enhances Communities
			</h4>
			<p className="text-[#888888] text-center font-work-sans text-[10px] md:text-[12px]">
				Motiwala Builders is a leading provider of high-quality construction services in Pakistan,
				committed to excellence, innovation, and sustainability. We take pride in delivering outstanding results that exceed our customers' expectations and create long-lasting value
				for the communities we serve. Our team of skilled professionals, architects, engineers,
				and craftsmen work closely with clients to understand their unique needs, preferences,
				and aspirations, and bring them to life through creative, efficient, and reliable building
				solutions
			</p>
			<p className="text-[#888888] text-center font-work-sans text-[10px] md:text-[12px]">
				Our mission is to build more than just structures. We aim to create exceptional living
				environments that inspire and elevate the human experience. From luxurious homes to
				commercial spaces, from industrial projects to public infrastructure, we approach every
				project with passion, creativity, and attention to detail. We utilize the latest technologies,
				materials, and construction techniques to ensure that our buildings are not only beautiful
				but also energy-efficient, durable, and environmentally friendly. At Motiwala Builders,
				we believe that our work is not just about erecting buildings but about enriching lives,
				fostering connections, and creating a better future for generations to come.
			</p>
			<p className="text-[#888888] text-center font-work-sans text-[10px] md:text-[12px]">
				As a company, we are dedicated to upholding the highest standards of ethics,
				professionalism, and customer service. We believe in transparency, honesty, and
				accountability in all our business dealings. We are committed to promoting a culture of
				continuous learning, innovation, and collaboration, where every member of our team is
				valued, respected, and empowered to contribute to our collective success. Our goal is to
				be recognized as a trusted and respected leader in the construction industry, renowned
				for our quality, reliability, and customer satisfaction
			</p>
			<div className="grid grid-cols-2 md:grid-cols-4 gap-2 my-[5%]"
			>
				{[
					{ background: Features1, heading: "Rich Heritage and Experience", paragraph: "With over 32 years in the real estate industry, the group has launched more than 57 projects, with 27 completed under the leadership of Mr. Wahid Abdul Qadir Motiwala." },
					{ background: Features2, heading: "Commitment to Quality", paragraph: "Motiwala Builders is committed to raising the bar for quality housing and commercial projects, ensuring that every development meets stringent quality benchmarks." },
					{ background: Features3, heading: "Expert Design and Engineering", paragraph: "Recent projects have been designed and engineered by experts from the UAE, incorporating global standards and innovations into their developments." },
					{ background: Features4, heading: "Customer-Centric Approach", paragraph: "The group’s commitment to customer satisfaction ensures that all projects are designed with the needs and desires of their clients in mind." }
				].map((a) => (
					<div
						className="h-[40vh] md:h-[70vh] w-full bg-[#000] p-4 pb-6 flex flex-col justify-end align-center relative overflow-hidden group"
					>
						<div
							className="absolute inset-0 bg-no-repeat bg-cover transition-transform duration-300 group-hover:scale-110"
							style={{
								backgroundImage: `linear-gradient(rgba(0,0,0,0.1), rgba(0,0,0,0.1)), url('${a?.background}')`,
								backgroundRepeat: "no-repeat",
								backgroundSize: "cover"
							}}
						></div>

						<div className="relative z-10">
							<p className="text-[15px] md:text-[25px] text-white font-sans  group-hover:opacity-0 opacity-100 ">
								{a?.heading}
							</p>
						</div>

						<div className="absolute flex flex-col justify-center items-start bg-white/90 p-6 opacity-0 transition-opacity duration-300 group-hover:opacity-100">
							<p className="text-[18px] md:text-[35px] font-semibold text-[#000] font-playball ">
								{a?.heading}
							</p>
							<p className="mt-4 text-[9px] md:text-[12px] font-semibold text-[#000] font-poppins">
								{a?.paragraph}
							</p>
						</div>
					</div>
				))}
			</div>


			<h4 className="text-primary text-center font-poppins text-[25px] md:text-[35px]">
				Lasting Communities with Innovation and Quality
			</h4>
			<h4 className="text-[#888888] text-center font-work-sans text-[13px] md:text-[15px] my-[20px]">
				<b className="text-secondary">Motiwala Builders</b> is committed to innovation, quality, and creating communities that stand the test of time
			</h4>
			<p className="text-[#888888] text-center font-work-sans text-[10px] md:text-[12px]">
				At Motiwala Builders, our vision is to be the leading builder and developer company in
				Pakistan, known for delivering exceptional quality, innovation, and value to our clients. We
				strive to create innovative and sustainable communities that enhance the quality of life for
				our clients and contribute to the development of the country.
			</p>
			<p className="text-[#888888] text-center font-work-sans text-[10px] md:text-[12px]">
				We aim to achieve our vision by staying at the forefront of the latest developments in the
				construction industry, leveraging cutting-edge technology and techniques to create
				beautiful, functional, and sustainable spaces. Our team is committed to maintaining the
				highest standards of integrity, transparency, and professionalism in everything we do,
				building long-term relationships with our clients based on trust and mutual respect.
				Ultimately, our goal is to create communities that people are proud to call home, where
				they can live, work, and thrive in an environment that supports their health, wellbeing, and
				happiness. We believe that by delivering exceptional quality and value, we can make a
				positive contribution to society and create a legacy that will endure for generations to
				come
			</p>
			<div
				className="w-full md:w-[50%] grid grid-cols-3 place-items-center h-full my-[5%]"
			>
				{[
					Logo1,
					Logo2,
					Logo3,
				].map((a) => (
					<img
						className="w-[80px] md:w-[120px] h-[80px] md:h-[120px]"
						src={a}
					/>
				))}
			</div>
		</div>
	);
}

export default AboutUs;
