import React, { useEffect } from "react";
import Image from "../assets/BG.png";

// import AOS from "aos";
// import "aos/dist/aos.css";

function Message() {
	// useEffect(() => {
	// 	AOS.init();
	// }, []);
	return (
		<div
			id="CEO"
			className=" bg-no-repeat bg-cover bg-center  relative overflow-hidden flex flex-col justify-center align-center items-center md:px-[5%] flex-shrink-0 p-[40px]"
			style={{
				background: `linear-gradient(to right, #434343 0%, #000000 97.98%), url('${Image}')`,
				backgroundRepeat: "no-repeat",
				backgroundSize: "cover"
			}}
		>
			<div className="w-[100%] gap-4 flex justify-center flex-col items-center">
				<div className="flex justify-start items-end w-full">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="87"
						height="100"
						viewBox="0 0 87 100"
						fill="none"
						className="w-[30px] h-[30px] lg:w-[60px] lg:h-[60px]"
					>
						<path
							d="M0 42.1875C0 29.2383 10.4283 18.75 23.3036 18.75H24.8571C28.2944 18.75 31.0714 21.543 31.0714 25C31.0714 28.457 28.2944 31.25 24.8571 31.25H23.3036C17.3029 31.25 12.4286 36.1523 12.4286 42.1875V43.75H24.8571C31.7123 43.75 37.2857 49.3555 37.2857 56.25V68.75C37.2857 75.6445 31.7123 81.25 24.8571 81.25H12.4286C5.57344 81.25 0 75.6445 0 68.75V62.5V56.25V42.1875ZM49.7143 42.1875C49.7143 29.2383 60.1426 18.75 73.0179 18.75H74.5714C78.0087 18.75 80.7857 21.543 80.7857 25C80.7857 28.457 78.0087 31.25 74.5714 31.25H73.0179C67.0172 31.25 62.1429 36.1523 62.1429 42.1875V43.75H74.5714C81.4266 43.75 87 49.3555 87 56.25V68.75C87 75.6445 81.4266 81.25 74.5714 81.25H62.1429C55.2877 81.25 49.7143 75.6445 49.7143 68.75V62.5V56.25V42.1875Z"
							fill="white"
						/>
					</svg>
				</div>
				<p
					className="w-[80%] text-white text-center font-playfair text-[30px] font-[700]"
				>
					Dear Valued Partners and Clients,
				</p>
				{[
					"Welcome to Motiwala Builders, where we transform dreams into reality through the  power of innovation and excellence. For 32 years, we have been a trusted name in the real estate industry, delivering quality construction, innovative designs, and unparalleled customer service.At Motiwala Builders, we are committed to providing the highest standards of quality in all aspects of our operations.From planning and design to construction and project management, our team of experts works tirelessly to ensure that every project we undertake exceeds our clients' expectations.",
					"As a company, we are always looking for ways to improve and innovate. We continuously invest in the latest technologies and techniques to stay ahead of the curve, and we constantly seek out new ideas and best practices to enhance our operations. Our success over the past 25 years is a testament to the hard work and dedication of our team, as well as the trust and confidence of our clients. We look forward to continue to serve our clients and partners with the same level of commitment and excellence that has defined our company for the past two and a half decades.", ,
					"Thank you for choosing Motiwala Builders"
				].map((a) => (
					<p
						className="text-white text-center font-poppins text-[12px] font-[300]"
					>
						{a}
					</p>
				))}
				<div className="flex justify-end items-end w-full">
					<svg
						// data-aos="fade-up"
						// data-aos-offset="200"
						// data-aos-easing="ease-in-sine"
						// data-aos-duration="1000"
						xmlns="http://www.w3.org/2000/svg"
						width="87"
						height="100"
						viewBox="0 0 87 100"
						fill="none"
						className="w-[30px] h-[30px] lg:w-[60px] lg:h-[60px]"
					>
						<path
							d="M87 57.8125C87 70.7617 76.5717 81.25 63.6964 81.25H62.1429C58.7056 81.25 55.9286 78.457 55.9286 75C55.9286 71.543 58.7056 68.75 62.1429 68.75H63.6964C69.6971 68.75 74.5714 63.8477 74.5714 57.8125V56.25H62.1429C55.2877 56.25 49.7143 30.6445 49.7143 43.75V31.25C49.7143 24.3555 55.2877 18.75 62.1429 18.75H74.5714C81.4266 18.75 87 24.3555 87 31.25V37.5V43.75V57.8125ZM37.2857 57.8125C37.2857 70.7617 26.8574 81.25 13.9821 81.25H12.4286C8.99129 81.25 6.21429 78.457 6.21429 75C6.21429 71.543 8.99129 68.75 12.4286 68.75H13.9821C19.9828 68.75 24.8571 63.8477 24.8571 57.8125V56.25L12.4286 56.25C5.57343 56.25 0 30.6445 0 43.75V31.25C0 24.3555 5.57343 18.75 12.4286 18.75H24.8571C31.7123 18.75 37.2857 24.3555 37.2857 31.25V37.5V43.75L37.2857 57.8125Z"
							fill="white"
						/>
					</svg>
				</div>
			</div>
			<p
				className="w-full text-white text-center font-playball text-xl md:text-2xl lg:text-3xl font-normal"
			>
				CEO
			</p>
		</div>
	);
}

export default Message;
