import React, { useEffect, useState } from "react";

import Project1 from "../assets/Projects/Project (1).jpeg";
import Project2 from "../assets/Projects/Project (2).jpeg";
import Project3 from "../assets/Projects/Project (3).jpeg";
import Project4 from "../assets/Projects/Project (4).jpeg";
import Project5 from "../assets/Projects/Project (5).jpeg";
import Project6 from "../assets/Projects/Project (6).jpeg";
import Project7 from "../assets/Projects/Project (7).jpeg";
import Project8 from "../assets/Projects/Project (8).jpeg";
import Project9 from "../assets/Projects/Project (9).jpeg";
import Project10 from "../assets/Projects/Project (10).jpeg";
import Project11 from "../assets/Projects/Project (11).jpeg";
import Project12 from "../assets/Projects/Project (12).jpeg";
import Project13 from "../assets/Projects/Project (13).jpeg";
import Project14 from "../assets/Projects/Project (14).jpeg";
import Project15 from "../assets/Projects/Project (15).jpeg";
import Project16 from "../assets/Projects/Project (16).jpeg";
import Project17 from "../assets/Projects/Project (17).jpeg";
import Project18 from "../assets/Projects/Project (18).jpeg";

import { ReactComponent as Home1 } from "../assets/Home (1).svg";
import { ReactComponent as Home2 } from "../assets/Home (2).svg";
import { ReactComponent as Home3 } from "../assets/Home (3).svg";
import { ReactComponent as Home4 } from "../assets/Home (4).svg";
import { ReactComponent as Home5 } from "../assets/Home (5).svg";
import { ReactComponent as Home6 } from "../assets/Home (6).svg";
import { ReactComponent as Home7 } from "../assets/Home (7).svg";
import { ReactComponent as Home8 } from "../assets/Home (8).svg";

function Pinnacale() {
	const [PropertyType, setPropertyType] = useState("All")
	return (
		<div id="Services" className={`${(!PropertyType || PropertyType == "") && "h-[100vh]"} relative overflow-hidden my-[5%] flex flex-col justify-center align-center items-center`}>
			<h1
				className="text-[#000] text-center font-playfair text-3xl md:text-4xl lg:text-5xl font-semibold leading-[188.235%]"
			>
				Pinnacle Creations{" "}
			</h1>
			<div
				// data-aos="fade-down"
				className="grid grid-cols-3 md:grid-cols-6 md:gap-2 my-[5%] mx-[5%]">
				{[
					{ Image: <Home8 />, Name: "All" },
					{ Image: <Home1 />, Name: "Commercial Property" },
					{ Image: <Home2 />, Name: "Hospitality Property" },
					{ Image: <Home4 />, Name: "Mixed-Use Development" },
					{ Image: <Home5 />, Name: "Specialized Facility" },
					{ Image: <Home6 />, Name: "Residential Property" },
				].map((a, i) => (
					<div
						className={`flex flex-col items-center w-[100px] p-[10px 16px] gap-2 cursor-pointer`}
						onClick={() => {
							setPropertyType("")
							setTimeout(() => {
								setPropertyType(a?.Name)
							}, 10);
						}}
					>
						{a?.Image}
						<p
							className={`${a?.Name == PropertyType ? " transition-transform duration-300  text-primary font-bold" : "transition-transform duration-300 hover:text-secondary text-[#565656] font-thin"} text-center font-inter text-xs md:text-sm  leading-normal`}
						>
							{a?.Name}
						</p>
					</div>
				))}
			</div>
			{PropertyType && (
				<div className="grid grid-cols-2"
					// data-aos="zoom-in-down"
				>
					{[
						{
							"background": Project1,
							"Type": "Completed",
							"Heading": "NICE TRADE ORBIT",
							"Text": "Nice Trade Orbit offers premium office spaces and showrooms on Shahrah-e-Faisal, Karachi. This dynamic commercial project combines state-of-the-art facilities with a strategic location, making it ideal for businesses seeking prominence in Karachi's bustling commercial hub.",
							"Category": "Commercial Property"
						},
						{
							"background": Project2,
							"Type": "Completed",
							"Heading": "DUBAI PALACE (GUEST HOUSE)",
							"Text": "Dubai Palace in Gulistan-e-Johar, Karachi, offers luxurious guest accommodations with modern amenities. Blending comfort and elegance, this project ensures travelers a premium experience with convenient access to the city, promising exceptional hospitality and high-quality service.",
							"Category": "Hospitality Property"
						},
						{
							"background": Project3,
							"Type": "Completed",
							"Heading": "PARKING & STORE SHED",
							"Text": "This project supports Dubai Palace in Gulistan-e-Johar by providing secure parking and storage facilities. Designed for efficiency and convenience, it enhances operational functionality with modern infrastructure tailored to meet the needs of both residents and visitors.",
							"Category": "Specialized Facility"
						},
						{
							"background": Project4,
							"Type": "Completed",
							"Heading": "AL-AMNA HEIGHTS",
							"Text": "Located on Khalid Bin Waleed Road, Al-Amna Heights is a mixed-use project offering residential units and showrooms. Its strategic location and modern amenities make it an attractive choice for residents and businesses seeking quality and convenience in Karachi.",
							"Category": "Mixed-Use Development"
						},
						{
							"background": Project5,
							"Type": "Completed",
							"Heading": "CITI CENTRE RESIDENCY",
							"Text": "Citi Centre Residency in Bahadurabad combines commercial and residential spaces with modern facilities. Designed to elevate urban living, it offers vibrant community living with strategic accessibility for residents and businesses in one of Karachi's most desirable locations.",
							"Category": "Mixed-Use Development"
						},
						{
							"background": Project6,
							"Type": "Completed",
							"Heading": "Z.N. TOWERS",
							"Text": "Z.N. Towers on Alamgir Road offers residential units and showrooms equipped with contemporary amenities. It caters to individuals seeking high-quality living and business opportunities in Karachi's urban landscape, emphasizing functionality and modern design.",
							"Category": "Mixed-Use Development"
						},
						{
							"background": Project7,
							"Type": "Completed",
							"Heading": "CITI AVENUE",
							"Text": "Citi Avenue on Shahrah-e-Faisal features modern office spaces and showrooms. Combining advanced infrastructure with a strategic location, it is perfect for businesses aiming to thrive in Karachi's vibrant commercial district.",
							"Category": "Commercial Property"
						},
						{
							"background": Project8,
							"Type": "Completed",
							"Heading": "CITI HEIGHTS",
							"Text": "Citi Heights on Shahrah-e-Faisal offers a blend of residential spaces and showrooms. Its prime location and contemporary amenities cater to urban professionals and businesses looking for comfort, convenience, and visibility in Karachi.",
							"Category": "Mixed-Use Development"
						},
						{
							"background": Project9,
							"Type": "Completed",
							"Heading": "MILLENNIUM MALL",
							"Text": "Millennium Mall, located on Rashid Minhas Road, is a premier shopping center offering retail outlets, dining, and entertainment options. It is a landmark destination in Karachi, catering to diverse customer preferences.",
							"Category": "Commercial Property"
						},
						{
							"background": Project10,
							"Type": "Completed",
							"Heading": "EROS COMPLEX",
							"Text": "Eros Complex on M.A. Jinnah Road blends residential and commercial spaces. With modern amenities and a central location, it offers urban convenience and lifestyle benefits for residents and businesses in Karachi.",
							"Category": "Mixed-Use Development"
						},
						{
							"background": Project11,
							"Type": "Completed",
							"Heading": "FEROZA HEIGHTS",
							"Text": "Feroza Heights in Bahadurabad provides affordable and comfortable residential units. With a focus on quality and modern amenities, it is ideal for families and individuals seeking practical living solutions in Karachi.",
							"Category": "Residential Property"
						},
						{
							"background": Project12,
							"Type": "Completed",
							"Heading": "AL-AMNA TOWN HOUSES",
							"Text": "Al-Amna Town Houses on Razi Road offer modern residential accommodations in a serene environment. Designed for convenience and comfort, they provide an excellent living option for those in Karachi’s urban areas.",
							"Category": "Residential Property"
						},
						{
							"background": Project13,
							"Type": "Completed",
							"Heading": "BELAIR LUXURY RESORT",
							"Text": "Belair Luxury Resort on Super Highway offers an unmatched retreat with luxurious amenities. Surrounded by breathtaking views, it provides an unparalleled experience for leisure travelers seeking exclusivity and relaxation near Karachi.",
							"Category": "Hospitality Property"
						},
						{
							"background": Project14,
							"Type": "Completed",
							"Heading": "AL-AMNA VILLAS",
							"Text": "Al-Amna Villas in Hill Park feature tranquil living with modern amenities. These residential units are perfect for families seeking peaceful yet convenient urban accommodations in Karachi.",
							"Category": "Residential Property"
						},
						{
							"background": Project15,
							"Type": "Completed",
							"Heading": "BAHADURABAD ARCADE",
							"Text": "Bahadurabad Arcade is a bustling commercial hub offering modern showrooms and spaces. Its strategic location makes it a prime choice for businesses in Karachi’s dynamic commercial sector.",
							"Category": "Commercial Property"
						},
						{
							"background": Project16,
							"Type": "Completed",
							"Heading": "AL-AMNA TOWER",
							"Text": "Al-Amna Tower on Tariq Road offers comfortable residential living with urban amenities. Its prime location and modern design cater to professionals and families seeking quality housing in Karachi.",
							"Category": "Residential Property"
						},
						{
							"background": Project17,
							"Type": "Completed",
							"Heading": "AL-RAHIMA GARDEN",
							"Text": "Al-Rahima Garden in Garden East offers affordable, comfortable residential units with modern amenities, ideal for families seeking practical housing solutions in a convenient Karachi location.",
							"Category": "Residential Property"
						},
						{
							"background": Project18,
							"Type": "Completed",
							"Heading": "CITI CLUB",
							"Text": "Citi Club on Khalid Bin Waleed Road combines residential units with showrooms. It offers modern amenities, making it an excellent choice for both urban living and commercial ventures in Karachi.",
							"Category": "Mixed-Use Development"
						}
					]
						.filter(a => {
							if (PropertyType == "All") return a
							else if (a?.Category == PropertyType) {
								return a
							}
						})
						.map((a, i) => (
							<div
								className="h-[40vh] md:h-[70vh] w-[50vw] p-4 pb-6 flex flex-col justify-end align-center relative overflow-hidden group"
							>
								<div
									className="absolute inset-0 bg-no-repeat bg-cover transition-transform duration-300 group-hover:scale-110"
									style={{
										backgroundImage: `linear-gradient(rgba(0,0,0,0.1), rgba(0,0,0,0.1)), url('${a?.background}')`,
										backgroundRepeat: "no-repeat",
										backgroundSize: "cover"
									}}
								></div>

								<div className="relative z-10">
									<p className="text-[22px] md:text-[35px] font-semibold text-white font-worksans  group-hover:opacity-0 opacity-100 ">
										{a?.Heading}
									</p>
								</div>

								<div className="absolute inset-0 flex flex-col justify-center items-start bg-white/10 p-6 opacity-0 transition-opacity duration-300 group-hover:opacity-100">
									<p className="text-[18px] md:text-[35px] font-semibold text-[#000] font-playball ">
										{a?.Heading}
									</p>
									<p className="mt-4 text-[9px] md:text-[12px] font-semibold text-[#000] font-poppins">
										{a?.Text}
									</p>
								</div>
							</div>

						))}
				</div>
			)}
		</div>
	);
}

export default Pinnacale;
